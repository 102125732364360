<template>
  <div>
    <title-header :title="title" :buttons="headerButtons"></title-header>

    <v-row class="mr-5">
      <v-spacer></v-spacer>
      <v-col justify="end" md="4">
        <v-text-field
          v-model="search"
          class="ml-5"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :search="search"
      :headers="headers"
      :items="fields"
      :items-per-page="20"
      class="elevation-1 pl-10 pr-10"
    >
      <template v-slot:item.buttons="{ item }">
          <div class="b-t-n">
            <v-btn
              class=""
              rounded
              small
              outlined
              color="primary"
              @click="routeToEditField(item)"
            >Editar</v-btn>

            <v-btn
              class=""
              rounded
              small
              outlined
              color="red"
              @click="removeFieldDialog(item)"
              dark
            >Eliminar</v-btn>
          </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
      <v-card-title class="headline">Eliminar {{selectedItem.name}}</v-card-title>
      <v-card-text>¿Seguro que deseas eliminar este campo?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="removeFieldClicked(selectedItem)">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import TitleHeader from '@/components/app/title-header/title-header'
import { FIELDS_GET_FIELDS, FIELDS_REMOVE_FIELD } from '@/store/actions.type'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'field-list',
  props: {},
  data() {
    return {
      snackBarText: '',
      showSnackbar: false,
      search: '',
      snackBarTimeout: 1000,
      dialog: false,
      selectedItem: {},
      headers: [
        { text: 'Campo', value: 'name', width: 1000 },
        { text: 'Tipo', value: 'fieldType.name' },
        { text: '', value: 'buttons' }
      ],
      title: 'Campos',
      headerButtons: [
        {
          text: 'Nuevo campo',
          urlSlug: 'fields/create'
        }
        /* ,{
          text: 'Importar campos',
          urlSlug: 'fields/import'
        } */
      ]
    }
  },
  computed: {
    ...mapGetters({
      fields: 'getFields'
    })
  },
  methods: {
    ...mapActions({
      getFields: FIELDS_GET_FIELDS,
      removeField: FIELDS_REMOVE_FIELD
    }),
    removeFieldDialog(item) {
      this.dialog = true
      this.selectedItem = item
    },
    removeFieldClicked(item) {
      this.removeField(item)
        .then(reponse => {
          this.dialog = false
        })
        .catch(e => {
          this.snackBarText = ''
          this.showSnackbar = true
        })
    },
    routeToEditField(field) {
      this.$router.push('fields/' + field.id + '/edit')
    }
  },
  components: {
    TitleHeader
  },
  created() {
    // if (this.fields.length === 0) { this.getFields() }
    this.getFields()
  }
}
</script>

<style>
  .v-data-table td, .v-data-table th {
    padding: 0 3px;
  }
  .b-t-n button{
    margin-right: 5px;
    margin-left: 5px;
  }
</style>
